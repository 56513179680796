<template>
  <div class="dm_detail_card">
    <div class="video">
      <video class="video__inner" :src="videoUrl" controls></video>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WorkDmDetailLuk',
    props: {
      detail: {
        type: Object,
        default: () =>({})
      }
    },
    computed: {
      videoUrl () {
        if (!this.detail) return ''
        return this.detail.videoUrl || ''
      }
    }
  }
</script>

<style lang="less" scoped>
.dm_detail_card {
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  background-color:#FFF;
  .video {
    width: 100%;
    &__inner {
      width: 100%;
      border-radius: 6px;
      object-fit: contain;
      vertical-align: middle;
    }
  }
}
</style>