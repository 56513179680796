<template>
  <div class="dm_detail_card">
    <div class="question_mask"></div>
    <div v-if="question">
      <FormPreview :exam="question" />
    </div>
    <div v-if="contentList && contentList.length" class="content_list">
      <div class="divider_line"></div>
      <div v-for="item in contentList" :key="item.id" class="content_item">
        <div class="content_item_label">{{  item.contentTypeStr  }}</div>
        <div class="content_item_content">{{ item.data && item.data.content || '' }}</div>
      </div>
    </div>
    <div class="read_explain" v-if="detail.reading">
      <div class="divider_line"></div> 
      <div class="read_explain_title">读后感</div>
      <div class="read_explain_content" v-html="detail.reading"></div>
      <div class="read_explain_desc">{{ detail.reading.length }} 字</div>
    </div>
  </div>
</template>

<script>
  import FormPreview from '@/views/i-surveys/views/form/preview'
  export default {
    name: 'WorkDmDetailWenxian',
    components: { FormPreview },
    props: {
      detail: {
        type: Object,
        default: () =>({})
      }
    },
    computed: {
      content () {
        if (!this.detail) return null
        if (!this.detail.content) return null
        const content = JSON.parse(this.detail.content)
        return content;
      },
      contentList () {
        if (!this.content) return []
        if (!this.content.content) return []
        return JSON.parse(this.content.content)
      },
      question () {
        if (!this.content) return []
        if (!this.content.questionAnswer) return []
        const question = JSON.parse(this.content.questionAnswer).map(item => {
          item.content.value = item.content.optionList.findIndex(val => val.key === item.content.value)
          return item.content
        })
        return question
      }
    }
  }
</script>

<style lang="less" scoped>
.dm_detail_card {
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  background-color:#FFF;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px 0;
  background-color:#FFF;
  .question_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
  .content_list {
    padding: 0 16px;
    .content_item {
      margin-bottom: 20px;
      &_label {
        font-weight: bold;
      }
      &_content {
        margin-top: 10px;
        text-align: justify;
        border: 1px solid #ddd;
        border-radius: 6px;
        padding: 10px;
      }
    }
  }
  .divider_line {
    height: 1px;
    background-color: #ddd;
    margin: 20px 0;
  }
  .read_explain {
    width: 100%;
    .read_explain_title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .read_explain_content {
      text-align: justify;
    }
    .read_explain_desc {
      margin-top: 10px;
      text-align: right;
      font-size: 14px;
      color:#a8abb2;
    }
  }
}
</style>