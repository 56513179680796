<template>
  <div class="dm_detail_card">
    <div class="question_mask"></div>
    <div>
      <FormPreview :exam="question" />
    </div>
  </div>
</template>

<script>
  import FormPreview from '@/views/i-surveys/views/form/preview'
  export default {
    name: 'WorkDmDetailQuestion',
    components: { FormPreview },
    props: {
      detail: {
        type: Object,
        default: () =>({})
      }
    },
    computed: {
      question () {
        if (!this.detail) return []
        if (!this.detail.content) return []
        return JSON.parse(this.detail.content)
      }
    }
  }
</script>

<style lang="less" scoped>
.dm_detail_card {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px 0;
  background-color:#FFF;
  .question_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
}
</style>