<template>
  <div class="dm_detail_card">
    <div class="fenshen_title">{{ detail.title || '' }}</div>
    <div class="video">
      <video class="video__inner" :src="videoUrl" controls></video>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WorkDmDetailFenshen',
    props: {
      detail: {
        type: Object,
        default: () =>({})
      }
    },
    computed: {
      videoUrl () {
        if (!this.detail) return ''
        return this.detail.videoUrl || ''
      }
    }
  }
</script>

<style lang="less" scoped>
.dm_detail_card {
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  background-color:#FFF;
  .fenshen_title {
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    padding-bottom: 20px;
  }
  .video {
    width: 100%;
    &__inner {
      border-radius: 6px;
      width: 100%;
      object-fit: contain;
      vertical-align: middle;
    }
  }
}
</style>