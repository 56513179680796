<template>
  <div class="dm_detail_card">
    <iframe class="ppt_content" :src="`${detail.content}?ci-process=doc-preview&dstType=html`"></iframe>
  </div>
</template>

<script>
  export default {
    name: 'WorkDmDetailPpt',
    props: {
      detail: {
        type: Object,
        default: () =>({})
      }
    },
    computed: {
      videoUrl () {
        if (!this.detail) return ''
        return this.detail.videoUrl || ''
      }
    }
  }
</script>

<style lang="less" scoped>
.dm_detail_card {
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  background-color:#FFF;
  .ppt_content {
    border: none;
    outline: none;
    width: 100%;
    height: 90vh;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>