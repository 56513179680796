<template>
  <div class="dm_detail_card" v-if="detail.content">
    <div class="dp">
      <div class="dp_title">点评内容（{{ detail.content.length }}）字</div>
      <div class="dp_content">{{ detail.content }}</div>
      <div class="dp_list">
        <van-cell
          v-for="item in detail.doctorYelpTitleDTOs"
          :key="item.id"
          :title="item.name + '：'"
        >{{ item.score || 0 }}分</van-cell>
        <van-cell
          title="合并总分："
        >{{ totalScore }}分</van-cell>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WorkDmDetailDianping',
    props: {
      detail: {
        type: Object,
        default: () =>({})
      }
    },
    computed: {
      totalScore () {
        if (!this.detail) return 0
        if (!this.detail.doctorYelpTitleDTOs) return 0
        return (this.detail.doctorYelpTitleDTOs || []).reduce((val, item) => val + Number.parseFloat(item.score), 0)
      }
    }
  }
</script>

<style lang="less" scoped>
.dm_detail_card {
  margin-top: 14px;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  background-color:#FFF;
  .dp {
    &_title {
      font-weight: bold;
    }
    &_content {
      margin: 10px 0;
    }
    &_list {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #ddd;
      .van-cell {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>