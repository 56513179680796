<template>
  <div class="dm_detail_card">
    <div class="article_title">{{ detail.articleTitle || '' }}</div>
    <div class="video" v-if="detail.articleType == 3">
      <video class="video__inner" :src="videoUrl" controls></video>
    </div>
    <div v-else>
      <div class="article_content" v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WorkDmDetailArticle',
    props: {
      detail: {
        type: Object,
        default: () =>({})
      }
    },
    computed: {
      videoUrl () {
        if (!this.detail) return ''
        return this.detail.videoUrl || ''
      }
    }
  }
</script>

<style lang="less" scoped>
.dm_detail_card {
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  background-color:#FFF;
  .article_title {
    text-align: center;
    font-weight: bold;
    font-size: 21px;
    padding-bottom: 20px;
  }
  .article_content {
    word-break: break-all;
    text-align: justify;
    img {
      max-width:100%;
      vertical-align: middle;
    }
  }
  .video {
    width: 100%;
    &__inner {
      border-radius: 6px;
      width: 100%;
      object-fit: contain;
      vertical-align: middle;
    }
  }
}
</style>