<template>
  <div class="dm_detail">
    <component :is="componentName" :detail="detail"></component>
    <DmDetailDianping :detail="dpDetail"/>
  </div>
</template>

<script>
  import DmDetailLuk from './components/dm-detail-luk.vue'
  import DmDetailQuestion from './components/dm-detail-question.vue'
  import DmDetailArticle from './components/dm-detail-article.vue'
  import DmDetailFenshen from './components/dm-detail-fenshen.vue'
  import DmDetailWenxian from './components/dm-detail-wenxian.vue'
  import DmDetailPpt from './components/dm-detail-ppt.vue'
  import DmDetailVideo from './components/dm-detail-video.vue'
  import DmDetailDianping from './components/dm-detail-dianping.vue'
  export default {
    name: 'WorksDmDetail',
    components: { DmDetailLuk, DmDetailQuestion, DmDetailArticle, DmDetailFenshen, DmDetailWenxian, DmDetailPpt, DmDetailVideo, DmDetailDianping },
    data: () => ({
      detail: {},
      dpDetail: {}
    }),
    computed: {
      componentType () {
        if (this.dpDetail.id) return this.dpDetail.targetType
        return this.$route.params.type
      },
      componentName () {
        return {
          1: 'DmDetailLuk',
          2: 'DmDetailQuestion',
          3: 'DmDetailQuestion',
          4: 'DmDetailArticle',
          5: 'DmDetailArticle',
          10: 'DmDetailFenshen',
          15: 'DmDetailWenxian',
          16: 'DmDetailPpt',
          17: 'DmDetailVideo',
        }[this.componentType]
      },
      
    },
    mounted () {
      this.load()
    },
    methods: {
      api (type, id) {
        switch (type + '') {
          // 录课活动
          case '1': return this.$api.work.dmDetailLuk(id)
          // 临床研究活动
          case '2':
          // 问卷调研活动
          case '3': return this.$api.work.dmDetailQuestion(id)
          // 文章创作活动
          case '4': 
          case '5': return this.$api.work.dmDetailArticle(id)
          // i分身
          case '10': return this.$api.work.dmDetailFenshen(id)
          // 点评
          case '12': return this.$api.work.dmDetailDianping(id)
          // 文献
          case '15': return this.$api.work.dmDetailWenxian(id)
          // PPT征集
          case '16': return this.$api.work.dmDetailCollect(id)
          // 视频征集
          case '17': return this.$api.work.dmDetailCollect(id)
          default: return null
        }
      },
      load () {
        if (this.$route.params.type == 12) {
          this.getDpDetail()
        } else {
          this.getDetail()
        }
      },
      getDetail (type, id) {
        this.api(type || this.$route.params.type, id || this.$route.params.id)?.then(res => {
          if (!res.code) {
            this.detail = res.data
          }
        })
      },
      getDpDetail () {
        this.api(12, this.$route.params.id).then(res => {
          if (!res.code) {
            this.dpDetail = res.data
            this.getDetail(res.data.targetType, res.data.targetId)
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.dm_detail {
  padding: 12px;
}
</style>